<template>
  <div class="py-24 dark:bg-gray-900 bg-white">
    <div class="lg:container lg:mx-auto dark:text-white text-black sm:mx-5">
        
        <h1 class="lg:text-4xl sm:text-2xl lg:mb-7 ml-3 sm:mb-7 text-left">Обновления клиентской части "е-Декларант"</h1>
        <div class="sm:block lg:flex flex-wrap ">
          <div v-for="update in updateClient" :key="update.id" class=" sm:w-full lg:w-1/3 my-3 ">
            <div class="card m-3 p-3 dark:bg-gray-800 bg-blue-100 text-gray-700 dark:text-white rounded-md h-full">
              <div class="text-box mb-2 relative">
                <h3 class="text-lg mt-2 mb-8 w-3/4">{{ update.title }}</h3>
                <a :href="update.file" class="absolute top-0 right-0">
                  <img class="download" src="@/assets/img/icons/download-white.svg" alt="">
                </a>
                <span class="text-gray-400">{{ update.text }}</span>
              </div>
            </div>
          </div>
        </div>

    </div>  
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "SoftwareProductsUpdateClient",
  data() {
    return {
      updateClient: {},
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      axios.get(`https://declarant.by/rest/software/updates/client/${this.$route.params.code}`)
          .then((response) => this.updateClient = response.data.data)
    }
  }
}
</script>

<style scoped>
.download {
  height: 40px;
}
</style>